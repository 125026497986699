import PageLayout from '../layouts/PageLayout';
import Home from './Home';
import Login from './Login';
import Stream from './Stream';
import Power from './Power';
import Schedules from './Schedules';
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PresetPages from './PresetPages';
import Modes from './Modes';
import Messages from './Messages';

export const createRoutes = (store: any) => (
    <PageLayout>
        <Switch>
            <Route path='/login' render={(props) => Login(store, props)} />
            <Route path='/home' render={(props) => Home(store, props)} />
            <Route path='/stream' render={(props) => Stream(store, props)} />
            <Route path='/power' render={(props) => Power(store, props)} />
            <Route path='/schedules' render={(props) => Schedules(store, props)} />
            <Route path='/preset-pages' render={(props) => PresetPages(store, props)} />
            <Route path='/modes' render={(props) => Modes(store, props)} />
            <Route path='/messages' render={(props) => Messages(store, props)} />
            <Redirect from="/" to="/home" />
        </Switch>
    </PageLayout>
);

export default createRoutes;
