import {
  PresetPagesStateActions,
  PresetPagesStateTypes,
} from '../common/modules/presetpages/types';
import { ThunkAction } from 'redux-thunk';
import { IFullStorageShape } from '../store/app.types';
import {
  getPresetPages as getPresetPagesApi,
  createPresetPage as createPresetPageApi,
  deletePresetPage as deletePresetPageApi,
  updatePresetPage as updatePresetPageApi } from '../api/presetPages';
import { AxiosResponse } from 'axios';
import { PresetPage } from '../common/models/presetPage';
import { setAppMessage } from './appActions';
import { get } from 'lodash';
import config from '../config';

export const clearPresets = (): ThunkAction<void, IFullStorageShape, null, PresetPagesStateActions> => (dispatch: any) => {
  dispatch({ type: PresetPagesStateTypes.CLEAN_PRESETPAGES });
}

export const createPresetPage = (roomName: string, presetPage: PresetPage): ThunkAction<void, IFullStorageShape, null, PresetPagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: PresetPagesStateTypes.CREATE_PRESETPAGE_BEGIN });
  return createPresetPageApi(roomName, mainTv, presetPage)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: PresetPagesStateTypes.CREATE_PRESETPAGE_SUCCESS, presetPages: apiData.presetpages });
    } else {
      dispatch({ type: PresetPagesStateTypes.CREATE_PRESETPAGE_FAILURE });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not set presetPages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: PresetPagesStateTypes.CREATE_PRESETPAGE_FAILURE });
  });
};

export const updatePresetPage = (roomName: string, presetPage: PresetPage): ThunkAction<void, IFullStorageShape, null, PresetPagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: PresetPagesStateTypes.UPDATE_PRESETPAGE_BEGIN });
  return updatePresetPageApi(roomName, mainTv, presetPage)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: PresetPagesStateTypes.UPDATE_PRESETPAGE_SUCCESS, presetPages: apiData.presetpages });
    } else {
      dispatch({ type: PresetPagesStateTypes.UPDATE_PRESETPAGE_FAILURE });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not update presetPages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: PresetPagesStateTypes.UPDATE_PRESETPAGE_FAILURE });
  });
};

export const getPresetPages = (roomName: string): ThunkAction<void, IFullStorageShape, null, PresetPagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: PresetPagesStateTypes.GET_PRESETPAGES_BEGIN });
  return getPresetPagesApi(roomName, mainTv)
  .then((response: AxiosResponse<any>) => {
    const data = response.data;
    if (data.success) {
      dispatch({ type: PresetPagesStateTypes.GET_PRESETPAGES_SUCCESS, presetPages: data.presetpages });
    } else {
      dispatch({ type: PresetPagesStateTypes.GET_PRESETPAGES_FAILURE });
    }
  })
  .catch((err) => {
    const errorMsg = get(err, 'response.data.message') || `Could not get presetPages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: PresetPagesStateTypes.GET_PRESETPAGES_FAILURE });
  });
};

export const deletePresetPage = (roomName: string, id: number): ThunkAction<void, IFullStorageShape, null, PresetPagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: PresetPagesStateTypes.DELETE_PRESETPAGE_BEGIN });
  return deletePresetPageApi(roomName, mainTv, [id])
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: PresetPagesStateTypes.DELETE_PRESETPAGE_SUCCESS, presetPages: apiData.presetpages });
    } else {
      dispatch({ type: PresetPagesStateTypes.DELETE_PRESETPAGE_FAILURE });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not delete presetPages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: PresetPagesStateTypes.DELETE_PRESETPAGE_FAILURE });
  });
};
