import axios, { AxiosRequestConfig } from 'axios';
import { TV_HOSTS, commonHeaders } from './ApiManager';
import { Mode } from '../common/models/modes';

export const getModes = (roomName: string, tvName: string) => {
  const path = '/modes';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const createMode = (roomName: string, tvName: string, mode: Mode) => {
  const path = '/modes';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: mode,
    method: 'POST',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const updateMode = (roomName: string, tvName: string, mode: Mode) => {
  const path = '/modes';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: mode,
    method: 'PUT',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const deleteMode = (roomName: string, tvName: string, ids: number[]) => {
  const path = '/modes';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: {
      ids,
    },
    method: 'DELETE',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const syncModes = (roomName: string, tvName: string, modes: Mode[]) => {
  const path = '/allmodes';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: modes,
    method: 'POST',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};
