import { AnyAction } from 'redux';
import { PresetPage } from '../../../common/models/presetPage';

export interface IPresetPagesState {
    isGettingPresetPages: boolean;
    isUpdatingPresetPages: boolean;
    presetPages: PresetPage[];
}

export const enum PresetPagesStateTypes {
  CLEAN_PRESETPAGES = 'CLEAN_PRESETPAGES',
  CREATE_PRESETPAGE_BEGIN = 'CREATE_PRESETPAGE_BEGIN',
  CREATE_PRESETPAGE_SUCCESS = 'CREATE_PRESETPAGE_SUCCESS',
  CREATE_PRESETPAGE_FAILURE = 'CREATE_PRESETPAGE_FAILURE',
  DELETE_PRESETPAGE_BEGIN = 'DELETE_PRESETPAGE_BEGIN',
  DELETE_PRESETPAGE_SUCCESS = 'DELETE_PRESETPAGE_SUCCESS',
  DELETE_PRESETPAGE_FAILURE = 'DELETE_PRESETPAGE_FAILURE',
  GET_PRESETPAGES_BEGIN = 'GET_PRESETPAGES_BEGIN',
  GET_PRESETPAGES_SUCCESS = 'GET_PRESETPAGES_SUCCESS',
  GET_PRESETPAGES_FAILURE = 'GET_PRESETPAGES_FAILURE',
  UPDATE_PRESETPAGE_BEGIN = 'UPDATE_PRESETPAGE_BEGIN',
  UPDATE_PRESETPAGE_SUCCESS = 'UPDATE_PRESETPAGE_SUCCESS',
  UPDATE_PRESETPAGE_FAILURE = 'UPDATE_PRESETPAGE_FAILURE',
}

//PresetPages
export interface ICleanPresetPages extends AnyAction {
    type: PresetPagesStateTypes.CLEAN_PRESETPAGES;
}

export interface IGetPresetPagesBegin extends AnyAction {
    type: PresetPagesStateTypes.GET_PRESETPAGES_BEGIN;
}

export interface IGetPresetPagesSuccess extends AnyAction {
    type: PresetPagesStateTypes.GET_PRESETPAGES_SUCCESS;
    presetPages: PresetPage[];
}

export interface IGetPresetPagesFailure extends AnyAction {
    type: PresetPagesStateTypes.GET_PRESETPAGES_FAILURE;
}

export interface ICreatePresetPageBegin extends AnyAction {
    type: PresetPagesStateTypes.CREATE_PRESETPAGE_BEGIN;
}

export interface ICreatePresetPageSuccess extends AnyAction {
    type: PresetPagesStateTypes.CREATE_PRESETPAGE_SUCCESS;
    presetPages: PresetPage[];
}

export interface ICreatePresetPageFailure extends AnyAction {
    type: PresetPagesStateTypes.CREATE_PRESETPAGE_FAILURE;
}

export interface IDeletePresetPageBegin extends AnyAction {
    type: PresetPagesStateTypes.DELETE_PRESETPAGE_BEGIN;
}

export interface IDeletePresetPageSuccess extends AnyAction {
    type: PresetPagesStateTypes.DELETE_PRESETPAGE_SUCCESS;
    presetPages: PresetPage[];
}

export interface IDeletePresetPageFailure extends AnyAction {
    type: PresetPagesStateTypes.DELETE_PRESETPAGE_FAILURE;
}

export interface IUpdatePresetPageBegin extends AnyAction {
    type: PresetPagesStateTypes.UPDATE_PRESETPAGE_BEGIN;
}

export interface IUpdatePresetPageSuccess extends AnyAction {
    type: PresetPagesStateTypes.UPDATE_PRESETPAGE_SUCCESS;
    presetPages: PresetPage[];
}

export interface IUpdatePresetPageFailure extends AnyAction {
    type: PresetPagesStateTypes.UPDATE_PRESETPAGE_FAILURE;
}

export type PresetPagesStateActions =
  ICleanPresetPages |
  IGetPresetPagesBegin |
  IGetPresetPagesSuccess |
  IGetPresetPagesFailure |
  ICreatePresetPageBegin |
  ICreatePresetPageSuccess |
  ICreatePresetPageFailure |
  IDeletePresetPageBegin |
  IDeletePresetPageSuccess |
  IDeletePresetPageFailure |
  IUpdatePresetPageBegin |
  IUpdatePresetPageSuccess |
  IUpdatePresetPageFailure;
