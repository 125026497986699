// @ts-nocheck
import { createStore as createReduxStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { makeRootReducer } from './reducers';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'

const createStore = (history: any, initialState = {}) => {
    // ===============
    // Logger config
    // ===============
    const logger = createLogger({
        logErrors: true
    });

    const middleware: any[] = [routerMiddleware(history), thunk, logger];

    const enhancers: never[] = [];
    const composeEnhancers = composeWithDevTools({});

    const store = createReduxStore(
        makeRootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware),
            ...enhancers
        )
    );

    return store;
};

export default createStore;
