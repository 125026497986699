import axios, { AxiosRequestConfig } from 'axios';
import { TV_HOSTS, commonHeaders } from './ApiManager';
import { Message } from '../common/models/messages';

export const createMessage = (roomName: string, tvName: string, message: Message) => {
  const path = '/messages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: message,
    method: 'POST',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const updateMessage = (roomName: string, tvName: string, message: Message) => {
  const path = '/messages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: message,
    method: 'PUT',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const syncMessages = (roomName: string, tvName: string, messages: Message[]) => {
  const path = '/allmessages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: messages,
    method: 'POST',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const deleteMessage = (roomName: string, tvName: string, ids: number[]) => {
  const path = '/messages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: {
      ids,
    },
    method: 'DELETE',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const getMessages = (roomName: string, tvName: string) => {
  const path = '/messages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};
