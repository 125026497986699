import {
  ApplicationStateActions,
  ApplicationStateTypes,
} from '../common/modules/app/types';
import { ThunkAction } from 'redux-thunk';
import { IFullStorageShape } from '../store/app.types';
import {
  displayPages,
  healthCheck as healthCheckApi,
  restartServer  as restartServerApi,
  getPages as getPagesApi,
  getTvStatus as getTvStatusApi,
  powerTv as powerTvApi } from '../api/ApiManager';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { Page } from '../common/models/page';
import { clearPresets } from './presetPagesActions';
import { clearModes } from './modesActions';
import config from '../config';
import { Md5 } from "md5-typescript";
import { clearMessages } from './messagesActions';
import { get } from 'lodash';

export const selectRoom = (roomName: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.SELECT_ROOM, roomName });
}

export const login = (password: string, roomName: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  const encrypted = Md5.init(password);
  if (encrypted === config.passwords[roomName] || roomName === 'LOCAL') {
    dispatch({ type: ApplicationStateTypes.LOGIN });
    dispatch(push('/home'));
    localStorage.setItem('session', roomName);
  } else {
    dispatch({ type: ApplicationStateTypes.LOGIN_FAIL });
  }
}

export const logout = (): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.LOGOUT });
  dispatch(push('/login'));
  dispatch(clearPresets());
  dispatch(clearModes());
  dispatch(clearMessages());
  localStorage.removeItem('session');
}

export const sendPageUrl = (roomName: string, tvName: string, pages: Page[] | undefined, instant: boolean): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.SEND_PAGES_BEGIN, tvName });
  if (!pages || pages.length === 0) {
    const defaultPage: Page = {
      url: `${config.templateUrl}/default.html`,
      autoscroll: false,
    }
    pages = [defaultPage];
  }
  return displayPages(roomName, tvName, pages, instant)
  .then((response: AxiosResponse<any>) => {
    const success = response.data.success;
    if (success) {
      dispatch({ type: ApplicationStateTypes.SEND_PAGES_SUCCESS, tvName });
    } else {
      dispatch(sendPagesFailure(tvName, `Send Page Failed: ${response.data.message.response.message}`));
    }
  })
  .catch(() => {
    dispatch(sendPagesFailure(tvName, 'Send Page Failed'));
  });
};

export const getPages = (roomName: string, tvName: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.GET_PAGES_BEGIN, tvName });
  return getPagesApi(roomName, tvName)
  .then((response: AxiosResponse<any>) => {
    const data = response.data;
    if (data.success) {
      dispatch({ type: ApplicationStateTypes.GET_PAGES_SUCCESS, tvName, pages: data.pages });
    } else {
      dispatch({ type: ApplicationStateTypes.GET_PAGES_FAILURE, tvName, errorMsg: 'Get Pages Failed' });
    }
  })
  .catch(() => {
    dispatch({ type: ApplicationStateTypes.GET_PAGES_FAILURE, tvName, errorMsg: 'Get Pages Failed' });
  });
};

export const healthCheck = (roomName: string, tvName: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.HEALTH_CHECK_BEGIN, tvName });
  return healthCheckApi(roomName, tvName)
  .then((response: AxiosResponse<any>) => {
    const time = get(response, 'data.healthcheck.checks[0].serverTime', '');
    const version = get(response, 'data.healthcheck.checks[0].version', 'N/A');
    const serverStatus = get(response, 'data.healthcheck.checks[0].serverStatus', false);
    dispatch(healthCheckSuccess(tvName, time, version, serverStatus));
  })
  .catch(() => {
    dispatch(healthCheckFailure(tvName));
  });
};

export const getTvStatus = (roomName: string, tvName: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.GET_SCREEN_STATUS_BEGIN, tvName });
  return getTvStatusApi(roomName, tvName)
  .then((response: AxiosResponse<any>) => {
    const success = response.data.success;
    if (success) {
      const status = response.data.data.indexOf('status: standby') === -1;
      dispatch({ type: ApplicationStateTypes.GET_SCREEN_STATUS_SUCCESS, tvName, status });
    } else {
      const errorMsg = response.data.message;
      dispatch({ type: ApplicationStateTypes.GET_SCREEN_STATUS_FAILURE, tvName, errorMsg });
    }
  })
  .catch(() => {
    dispatch({ type: ApplicationStateTypes.GET_SCREEN_STATUS_FAILURE, tvName, errorMsg: 'Could not get Screen Status' });
  });
};

export const restartServer = (roomName: string, tvName: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  return restartServerApi(roomName, tvName)
  .then(() => {
    dispatch(triggerRestart(tvName));
  })
};

export const powerTv = (roomName: string, tvName: string, status: boolean): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.POWER_SCREEN_BEGIN, tvName });
  return powerTvApi(roomName, tvName, status)
  .then(() => {
    dispatch({ type: ApplicationStateTypes.POWER_SCREEN_END, tvName });
  })
  .catch(() => {
    dispatch({ type: ApplicationStateTypes.POWER_SCREEN_END, tvName });
  });
};

export const setPageToSend = (tvName: string, index: number, page: Page | null): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.SET_PAGE_TO_SEND, tvName, index, page });
};

export const setAppMessage = (message: string): ThunkAction<void, IFullStorageShape, null, ApplicationStateActions> => (dispatch: any) => {
  dispatch({ type: ApplicationStateTypes.SET_APP_MESSAGE, message });
};

export const sendPagesFailure = (tvName: string, errorMsg: string) =>
  ({ type: ApplicationStateTypes.SEND_PAGES_FAILURE, tvName, errorMsg });

export const healthCheckSuccess = (tvName: string, time: string, version: string, serverStatus: boolean) =>
  ({ type: ApplicationStateTypes.HEALTH_CHECK_SUCCESS, tvName, time, version, serverStatus });

export const healthCheckFailure = (tvName: string) =>
  ({ type: ApplicationStateTypes.HEALTH_CHECK_FAILURE, tvName });

export const triggerRestart = (tvName: string) =>
  ({ type: ApplicationStateTypes.TRIGGER_RESTART, tvName });
