import axios, { AxiosRequestConfig } from 'axios';

import config from '../config/';
import { Page } from '../common/models/page';

export const TV_HOSTS = config.tvHosts;

interface IHeaders {
  Accept: string;
  'Content-Type': string;
}

export const commonHeaders: IHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const displayPages = (roomName: string, tvName:string, pages: Page[], instant: boolean = false) => {
  const path = `/streams?instant=${instant}`;
  const data = {
    pages: pages.map(page => {
      return {
        url: page.url,
        delay: page.delay,
        autoscroll: page.autoscroll
      }
    })
  }
  const requestOptions: AxiosRequestConfig = {
    data,
    headers: { ...commonHeaders },
    timeout: 30000,
    method: 'POST',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const getPages = (roomName: string, tvName:string) => {
  const path = '/streams';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    timeout: 5000,
    method: 'GET',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const restartServer = (roomName: string, tvName:string) => {
  const path = '/restart';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'PATCH',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const healthCheck = (roomName: string, tvName:string) => {
  const path = '/v1/healthcheck';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    timeout: 10000,
    method: 'GET',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const powerTv = (roomName: string, tvName: string, power: boolean) => {
  const path = power ? '/tv/on' : '/tv/off';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'PATCH',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const getTvStatus = (roomName: string, tvName: string) => {
  const path = '/tv/status';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: TV_HOSTS[roomName][tvName] + path,
    timeout: 5000,
  };
  return axios(requestOptions);
};
