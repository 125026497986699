interface IConfig {
  tvHosts: object;
  templateUrl: string;
  passwords: object;
  version: string;
}

// First host listed will be the "brain" Server that will store pages, schedules and modes
const config: IConfig = {
  tvHosts: {
    /*LOCAL: {
      'Salimac': 'http://localhost:3001',
      LOCAL_2: 'http://localhost:3002',
      LOCAL_3: 'http://localhost:3003',
    },*/
    ICM_ROOM: {
      'ICM-1': 'http://icm-1.ad.axs.com:3001',
      'ICM-2': 'http://icm-2.ad.axs.com:3001',
      'ICM-3': 'http://icm-3.ad.axs.com:3001',
      'ICM-4': 'http://icm-4.ad.axs.com:3001',
      'ICM-5': 'http://icm-5.ad.axs.com:3001',
      'ICM-6': 'http://icm-6.ad.axs.com:3001',
      'ICM-7': 'http://icm-7.ad.axs.com:3001',
    },
    JIMMY_ROOM: {
      'JIMMY-1': 'https://jimmy-1.ad.axs.com',
      'JIMMY-2': 'https://jimmy-2.ad.axs.com',
      'JIMMY-3': 'https://jimmy-3.ad.axs.com',
      'JIMMY-4': 'https://jimmy-4.ad.axs.com',
    },
    OPS_ROOM: {
      'OPS-1': 'http://ops-1.ad.axs.com:3001',
      'OPS-2': 'http://ops-2.ad.axs.com:3001',
      'OPS-3': 'http://ops-3.ad.axs.com:3001',
      'OPS-4': 'http://ops-4.ad.axs.com:3001',
    },
  },
  passwords: {
    ICM_ROOM: '670ada2dbd9a5d8df928cf2c5e9accb8',
    JIMMY_ROOM: 'b4ecf4dd76a148439615ea97789c67b8',
    OPS_ROOM: 'c52926f2eca3ab7044b1ebb431a68f64',
  },
  // templateUrl: 'file:///home/icm/screenserver/',
  templateUrl: 'file:///Users/icm/screenserver/',
  version: '1.3.0',
};

export default config;
