import { Reducer } from 'redux';
import { IApplicationState } from '../common/modules/app/types';
import { IPresetPagesState } from '../common/modules/presetpages/types';
import { IModesState } from '../common/modules/modes/types';
import { IMessagesState } from '../common/modules/messages/types';
import { ISchedulesState } from '../common/modules/schedules/types';

export const APP_REDUCER_KEY = 'app';
export const PRESET_PAGES_REDUCER_KEY = 'presetPages';
export const MODES_REDUCER_KEY = 'modes';
export const MESSAGES_REDUCER_KEY = 'messages';
export const SCHEDULES_REDUCER_KEY = 'schedules';


export interface IFullStorageShape {
    [APP_REDUCER_KEY]: IApplicationState,
    [PRESET_PAGES_REDUCER_KEY]: IPresetPagesState,
    [MODES_REDUCER_KEY]: IModesState,
    [MESSAGES_REDUCER_KEY]: IMessagesState,
    [SCHEDULES_REDUCER_KEY]: ISchedulesState
}

export type StoreShape = Partial<IFullStorageShape>;
export type NamespaceKey = keyof StoreShape;

export type ReducerMap = Partial<
    { [k in NamespaceKey]: Reducer<IFullStorageShape[k]> }
>;
