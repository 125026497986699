import * as React from 'react';
import { AXSFlex, AXSFlexItem } from '@axs/axsui-components';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { APP_REDUCER_KEY } from '../../../store/app.types';
import { connect } from 'react-redux';
import { logout } from '../../../actions/appActions';
import config from '../../../config';

const Home = styled.span<any>`
  color: white;
  padding: 10px;
  text-decoration: none;
  &:hover {
    color: white;
  }
`;
const LoginLink = styled(Link)`
  color: white;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: white;
  }
`;
const LogoutLink = styled.a<any>`
  color: white;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: white;
  }
`;

interface IHeader {
  isAuthenticated: boolean;
  logout: () => void;
  selectedRoom: string;
}

export class Header extends React.Component<IHeader> {
  constructor(props: IHeader, context: any) {
    super(props, context);
  }

  public render() {
    const { selectedRoom, isAuthenticated } = this.props;
    return (
      <AXSFlex paddingLeft="20px" alignItems="center" justifyContent="space-between" width="100%" height="50px" backgroundColor="#0054a1">
        <AXSFlexItem paddingRight="20px">
          { selectedRoom && isAuthenticated ?
          <LogoutLink onClick={ this.handleLogout }>
            Logout
          </LogoutLink> :
          <LoginLink to="/login">
            Login
          </LoginLink>}
          <Home>
            AXS Screen Manager - {config.version} { isAuthenticated ? ` - ${selectedRoom}` : null }
          </Home>
        </AXSFlexItem>
      </AXSFlex>
    );
  }

  private handleLogout = () => {
    const { logout } = this.props;
    logout();
  }
}

const mapStateToProps = (state: any) => {
  return {
    selectedRoom: state[APP_REDUCER_KEY].selectedRoom,
    isAuthenticated: state[APP_REDUCER_KEY].isAuthenticated,
  };
};

export default connect(mapStateToProps, { logout })(Header);
