import { AnyAction } from 'redux';
import { Mode } from '../../../common/models/modes';

export interface IModesState {
    isGettingModes: boolean;
    isSyncingModes: object;
    isUpdatingModes: object;
    modes: Mode[];
}

export const enum ModesStateTypes {
  CLEAN_MODES = 'CLEAN_MODES',
  CREATE_MODE_BEGIN = 'CREATE_MODE_BEGIN',
  CREATE_MODE_SUCCESS = 'CREATE_MODE_SUCCESS',
  CREATE_MODE_FAILURE = 'CREATE_MODE_FAILURE',
  DELETE_MODE_BEGIN = 'DELETE_MODE_BEGIN',
  DELETE_MODE_SUCCESS = 'DELETE_MODE_SUCCESS',
  DELETE_MODE_FAILURE = 'DELETE_MODE_FAILURE',
  GET_MODES_BEGIN = 'GET_MODES_BEGIN',
  GET_MODES_SUCCESS = 'GET_MODES_SUCCESS',
  GET_MODES_FAILURE = 'GET_MODES_FAILURE',
  SYNC_MODES_BEGIN = 'SYNC_MODES_BEGIN',
  SYNC_MODES_SUCCESS = 'SYNC_MODES_SUCCESS',
  SYNC_MODES_FAILURE = 'SYNC_MODES_FAILURE',
  UPDATE_MODE_BEGIN = 'UPDATE_MODE_BEGIN',
  UPDATE_MODE_SUCCESS = 'UPDATE_MODE_SUCCESS',
  UPDATE_MODE_FAILURE = 'UPDATE_MODE_FAILURE',
}

//Modes
export interface ICleanModes extends AnyAction {
    type: ModesStateTypes.CLEAN_MODES;
}

export interface IGetModesBegin extends AnyAction {
    type: ModesStateTypes.GET_MODES_BEGIN;
}

export interface IGetModesSuccess extends AnyAction {
    type: ModesStateTypes.GET_MODES_SUCCESS;
    modes: Mode[];
}

export interface ISyncModesBegin extends AnyAction {
    type: ModesStateTypes.SYNC_MODES_BEGIN;
}

export interface ISyncModesSuccess extends AnyAction {
    type: ModesStateTypes.SYNC_MODES_SUCCESS;
    modes: Mode[];
    tvName: string;
    roomName: string;
}

export interface ISyncModesFailure extends AnyAction {
    type: ModesStateTypes.SYNC_MODES_FAILURE;
}

export interface IGetModesFailure extends AnyAction {
    type: ModesStateTypes.GET_MODES_FAILURE;
}

export interface ICreateModeBegin extends AnyAction {
    type: ModesStateTypes.CREATE_MODE_BEGIN;
    tvName: string;
}

export interface ICreateModeSuccess extends AnyAction {
    type: ModesStateTypes.CREATE_MODE_SUCCESS;
    modes: Mode[];
    tvName: string;
}

export interface ICreateModeFailure extends AnyAction {
    type: ModesStateTypes.CREATE_MODE_FAILURE;
    tvName: string;
}

export interface IDeleteModeBegin extends AnyAction {
    type: ModesStateTypes.DELETE_MODE_BEGIN;
    tvName: string;
}

export interface IDeleteModeSuccess extends AnyAction {
    type: ModesStateTypes.DELETE_MODE_SUCCESS;
    modes: Mode[];
    tvName: string;
}

export interface IDeleteModeFailure extends AnyAction {
    type: ModesStateTypes.DELETE_MODE_FAILURE;
    tvName: string;
}

export interface IUpdateModeBegin extends AnyAction {
    type: ModesStateTypes.UPDATE_MODE_BEGIN;
    tvName: string;
}

export interface IUpdateModeSuccess extends AnyAction {
    type: ModesStateTypes.UPDATE_MODE_SUCCESS;
    modes: Mode[];
    tvName: string;
}

export interface IUpdateModeFailure extends AnyAction {
    type: ModesStateTypes.UPDATE_MODE_FAILURE;
    tvName: string;
}

export type ModesStateActions =
  ICleanModes |
  IGetModesBegin |
  IGetModesSuccess |
  IGetModesFailure |
  ISyncModesBegin |
  ISyncModesSuccess |
  ISyncModesFailure |
  ICreateModeBegin |
  ICreateModeSuccess |
  ICreateModeFailure |
  IDeleteModeBegin |
  IDeleteModeSuccess |
  IDeleteModeFailure |
  IUpdateModeBegin |
  IUpdateModeSuccess |
  IUpdateModeFailure;
