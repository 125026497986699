import * as React from 'react';
import { AXSBlock, AXSFlex } from '@axs/axsui-components';
import Header from '../common/components/Header/';
import MainNav from "../common/components/MainNav";
import { APP_REDUCER_KEY } from '../store/app.types';
import { connect } from 'react-redux';
import { setAppMessage } from '../actions/appActions';
import { AXSNotificationStyled } from '../common/components/AxsNotificationStyled';

interface IPageLayout {
    appMessage: string;
    isAuthenticated: boolean;
    setAppMessage: (message: string) => void;
    children: any;
}

export class PageLayout extends React.Component<IPageLayout, any> {
    public render() {
        const { children, appMessage, isAuthenticated } = this.props;

        return(
            <AXSFlex flexWrap="wrap" justifyContent="center">
                <Header { ... this.props } />
                <AXSBlock padding="30px" width="1190px">
                    { isAuthenticated ? <MainNav /> : null }
                    { appMessage !== '' ? <AXSNotificationStyled handleClose={ this.handleCloseNotification } type="warning">{ appMessage }</AXSNotificationStyled> : null}
                    <AXSBlock className='page-layout__viewport'>
                        { children }
                    </AXSBlock>
                </AXSBlock>
            </AXSFlex>
        );
    }
    private handleCloseNotification = () => {
      const { setAppMessage } = this.props;
      setAppMessage('');
    }
}

const mapStateToProps = (state: any) => {
  return {
    appMessage: state[APP_REDUCER_KEY].appMessage,
    isAuthenticated: state[APP_REDUCER_KEY].isAuthenticated,
  };
};

export default connect(mapStateToProps, { setAppMessage })(PageLayout);
