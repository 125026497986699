import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from './containers/AppContainer';
import createStore from './store/createStore';
import './index.scss';
import './index.css';
import './App.css';

import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();
const initialState =  {};
const store = createStore(customHistory, initialState);
const mountNode = document.getElementById('root');

const render = () => {
  const routes = require('./routes/index').default(store);

  ReactDOM.render(
    <AppContainer store={ store } routes={ routes } history={ customHistory }/>,
    mountNode
  );
};

render();
