import { AnyAction } from 'redux';
import { Schedule } from '../../../common/models/schedule';

export interface ISchedulesState {
    isGettingSchedules: object;
    isSyncingSchedules: object;
    isUpdatingSchedules: object;
    schedules: Schedule[];
}

export const enum SchedulesStateTypes {
  CLEAN_SCHEDULES = 'CLEAN_SCHEDULES',
  CREATE_SCHEDULE_BEGIN = 'CREATE_SCHEDULE_BEGIN',
  CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS',
  CREATE_SCHEDULE_FAILURE = 'CREATE_SCHEDULE_FAILURE',
  DELETE_SCHEDULE_BEGIN = 'DELETE_SCHEDULE_BEGIN',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS',
  DELETE_SCHEDULE_FAILURE = 'DELETE_SCHEDULE_FAILURE',
  GET_SCHEDULES_BEGIN = 'GET_SCHEDULES_BEGIN',
  GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS',
  GET_SCHEDULES_FAILURE = 'GET_SCHEDULES_FAILURE',
  SYNC_SCHEDULES_BEGIN = 'SYNC_SCHEDULES_BEGIN',
  SYNC_SCHEDULES_SUCCESS = 'SYNC_SCHEDULES_SUCCESS',
  SYNC_SCHEDULES_FAILURE = 'SYNC_SCHEDULES_FAILURE',
  UPDATE_SCHEDULE_BEGIN = 'UPDATE_SCHEDULE_BEGIN',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_FAILURE = 'UPDATE_SCHEDULE_FAILURE',
}

//Schedules
export interface ICleanSchedules extends AnyAction {
    type: SchedulesStateTypes.CLEAN_SCHEDULES;
}

export interface IGetSchedulesBegin extends AnyAction {
    type: SchedulesStateTypes.GET_SCHEDULES_BEGIN;
}

export interface IGetSchedulesSuccess extends AnyAction {
    type: SchedulesStateTypes.GET_SCHEDULES_SUCCESS;
    schedules: Schedule[];
    tvName: string;
    roomName: string;
}

export interface IGetSchedulesFailure extends AnyAction {
    type: SchedulesStateTypes.GET_SCHEDULES_FAILURE;
}

export interface ISyncSchedulesBegin extends AnyAction {
    type: SchedulesStateTypes.SYNC_SCHEDULES_BEGIN;
}

export interface ISyncSchedulesSuccess extends AnyAction {
    type: SchedulesStateTypes.SYNC_SCHEDULES_SUCCESS;
    schedules: Schedule[];
    tvName: string;
    roomName: string;
}

export interface ISyncSchedulesFailure extends AnyAction {
    type: SchedulesStateTypes.SYNC_SCHEDULES_FAILURE;
}

export interface ICreateScheduleBegin extends AnyAction {
    type: SchedulesStateTypes.CREATE_SCHEDULE_BEGIN;
    tvName: string;
}

export interface ICreateScheduleSuccess extends AnyAction {
    type: SchedulesStateTypes.CREATE_SCHEDULE_SUCCESS;
    tvName: string;
    roomName: string;
    schedules: Schedule[];
}

export interface ICreateScheduleFailure extends AnyAction {
    type: SchedulesStateTypes.CREATE_SCHEDULE_FAILURE;
    tvName: string;
}

export interface IDeleteScheduleBegin extends AnyAction {
    type: SchedulesStateTypes.DELETE_SCHEDULE_BEGIN;
    tvName: string;
}

export interface IDeleteScheduleSuccess extends AnyAction {
    type: SchedulesStateTypes.DELETE_SCHEDULE_SUCCESS;
    tvName: string;
    schedules: Schedule[];
    roomName: string;
}

export interface IDeleteScheduleFailure extends AnyAction {
    type: SchedulesStateTypes.DELETE_SCHEDULE_FAILURE;
    tvName: string;
}

export interface IUpdateScheduleBegin extends AnyAction {
    type: SchedulesStateTypes.UPDATE_SCHEDULE_BEGIN;
    tvName: string;
}

export interface IUpdateScheduleSuccess extends AnyAction {
    type: SchedulesStateTypes.UPDATE_SCHEDULE_SUCCESS;
    tvName: string;
    schedules: Schedule[];
    roomName: string;
}

export interface IUpdateScheduleFailure extends AnyAction {
    type: SchedulesStateTypes.UPDATE_SCHEDULE_FAILURE;
    tvName: string;
}

export type SchedulesStateActions =
  ICleanSchedules |
  IGetSchedulesBegin |
  IGetSchedulesSuccess |
  IGetSchedulesFailure |
  ISyncSchedulesBegin |
  ISyncSchedulesSuccess |
  ISyncSchedulesFailure |
  ICreateScheduleBegin |
  ICreateScheduleSuccess |
  ICreateScheduleFailure |
  IDeleteScheduleBegin |
  IDeleteScheduleSuccess |
  IDeleteScheduleFailure |
  IUpdateScheduleBegin |
  IUpdateScheduleSuccess |
  IUpdateScheduleFailure;
