import {
  IMessagesState,
  MessagesStateActions,
  MessagesStateTypes,
  IGetMessagesBegin,
  IGetMessagesSuccess,
  IGetMessagesFailure,
  ICreateMessageBegin,
  ICleanMessages,
  ISyncMessagesBegin,
  ISyncMessagesSuccess,
  ISyncMessagesFailure} from './types';
import config from '../../../config';

export const initialState: IMessagesState = {
    isGettingMessages: false,
    isSyncingMessages: {},
    isUpdatingMessages: {},
    messages: [],
};

const beginHandler = (state: IMessagesState, { tvName }: ICreateMessageBegin) => {
  const newState = { ...state };
  const newUpdate = { ... newState.isUpdatingMessages };
  newUpdate[tvName] = true;
  return Object.assign(newState, { isUpdatingMessages: newUpdate });
}

const successHandler = (state: IMessagesState, { messages, tvName }: any) => {
  const newState = { ...state };
  const newUpdate = { ... newState.isUpdatingMessages };
  newUpdate[tvName] = false;
  return Object.assign(newState, { isUpdatingMessages: newUpdate, messages });
}

const failureHandler = (state: IMessagesState, { tvName }: any) => {
  const newState = { ...state };
  const newUpdate = { ... newState.isUpdatingMessages };
  newUpdate[tvName] = false;
  return Object.assign(newState, { isUpdatingMessages: newUpdate });
}

const ACTION_HANDLERS = {
  [MessagesStateTypes.CREATE_MESSAGE_BEGIN]: beginHandler,
  [MessagesStateTypes.DELETE_MESSAGE_BEGIN]: beginHandler,
  [MessagesStateTypes.UPDATE_MESSAGE_BEGIN]: beginHandler,

  [MessagesStateTypes.CREATE_MESSAGE_SUCCESS]: successHandler,
  [MessagesStateTypes.DELETE_MESSAGE_SUCCESS]: successHandler,
  [MessagesStateTypes.UPDATE_MESSAGE_SUCCESS]: successHandler,

  [MessagesStateTypes.CREATE_MESSAGE_FAILURE]: failureHandler,
  [MessagesStateTypes.DELETE_MESSAGE_FAILURE]: failureHandler,
  [MessagesStateTypes.UPDATE_MESSAGE_FAILURE]: failureHandler,

  [MessagesStateTypes.GET_MESSAGES_BEGIN]: (state: IMessagesState, { }: IGetMessagesBegin) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingMessages: true });
  },
  [MessagesStateTypes.GET_MESSAGES_SUCCESS]: (state: IMessagesState, { messages }: IGetMessagesSuccess) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingMessages: false, messages });
  },
  [MessagesStateTypes.GET_MESSAGES_FAILURE]: (state: IMessagesState, { }: IGetMessagesFailure) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingMessages: false });
  },
  [MessagesStateTypes.SYNC_MESSAGES_BEGIN]: (state: IMessagesState, { tvName }: ISyncMessagesBegin) => {
    const newState = { ...state };
    const isSyncingMessages = { ...newState.isSyncingMessages };
    isSyncingMessages[tvName] = true;
    return Object.assign(newState, { isSyncingMessages });
  },
  [MessagesStateTypes.SYNC_MESSAGES_SUCCESS]: (state: IMessagesState, { tvName, messages, roomName }: ISyncMessagesSuccess) => {
    const newState = { ...state };
    const isSyncingMessages = { ...newState.isSyncingMessages };
    isSyncingMessages[tvName] = false;
    const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
    if (mainTv === tvName) {
      return Object.assign(newState, { isSyncingMessages, messages });
    }
    return Object.assign(newState, { isSyncingMessages });

  },
  [MessagesStateTypes.SYNC_MESSAGES_FAILURE]: (state: IMessagesState, { tvName }: ISyncMessagesFailure) => {
    const newState = { ...state };
    const isSyncingMessages = { ...newState.isSyncingMessages };
    isSyncingMessages[tvName] = false;
    return Object.assign(newState, { isSyncingMessages });
  },
  [MessagesStateTypes.CLEAN_MESSAGES]: (state: IMessagesState, { }: ICleanMessages) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingMessages: false, isUpdatingMessages: false, messages: [] });
  },
};

export default function reducer(
    state: IMessagesState = initialState,
    action: MessagesStateActions
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
