import {
  ModesStateActions,
  ModesStateTypes,
} from '../common/modules/modes/types';
import { ThunkAction } from 'redux-thunk';
import { IFullStorageShape } from '../store/app.types';
import {
  getModes as getModesApi,
  createMode as createModeApi,
  deleteMode as deleteModeApi,
  syncModes as syncModesApi,
  updateMode as updateModeApi } from '../api/modes';
import { AxiosResponse } from 'axios';
import { Mode } from '../common/models/modes';
import { setAppMessage } from './appActions';
import { get } from 'lodash';
import config from '../config';

export const clearModes = (): ThunkAction<void, IFullStorageShape, null, ModesStateActions> => (dispatch: any) => {
  dispatch({ type: ModesStateTypes.CLEAN_MODES });
}

export const createMode = (roomName: string, mode: Mode, tvName?: string): ThunkAction<void, IFullStorageShape, null, ModesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: ModesStateTypes.CREATE_MODE_BEGIN, tvName });
  return createModeApi(roomName, tvName || mainTv, mode)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: ModesStateTypes.CREATE_MODE_SUCCESS, modes: apiData.modes, tvName });
    } else {
      dispatch({ type: ModesStateTypes.CREATE_MODE_FAILURE, tvName });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not set Modes ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: ModesStateTypes.CREATE_MODE_FAILURE, tvName });
  });
};

export const updateMode = (roomName: string, mode: Mode, tvName?: string): ThunkAction<void, IFullStorageShape, null, ModesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: ModesStateTypes.UPDATE_MODE_BEGIN, tvName });
  return updateModeApi(roomName, tvName || mainTv, mode)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: ModesStateTypes.UPDATE_MODE_SUCCESS, modes: apiData.modes, tvName });
    } else {
      dispatch({ type: ModesStateTypes.UPDATE_MODE_FAILURE, tvName });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not update Modes ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: ModesStateTypes.UPDATE_MODE_FAILURE, tvName });
  });
};

export const getModes = (roomName: string): ThunkAction<void, IFullStorageShape, null, ModesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: ModesStateTypes.GET_MODES_BEGIN });
  return getModesApi(roomName, mainTv)
  .then((response: AxiosResponse<any>) => {
    const data = response.data;
    if (data.success) {
      dispatch({ type: ModesStateTypes.GET_MODES_SUCCESS, modes: data.modes });
    } else {
      dispatch({ type: ModesStateTypes.GET_MODES_FAILURE });
    }
  })
  .catch((err) => {
    const errorMsg = get(err, 'response.data.message') || `Could not get Modes ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: ModesStateTypes.GET_MODES_FAILURE });
  });
};

export const deleteMode = (roomName: string, id: number, tvName?:string): ThunkAction<void, IFullStorageShape, null, ModesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: ModesStateTypes.DELETE_MODE_BEGIN, tvName });
  return deleteModeApi(roomName, tvName || mainTv, [id])
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: ModesStateTypes.DELETE_MODE_SUCCESS, modes: apiData.modes, tvName });
    } else {
      dispatch({ type: ModesStateTypes.DELETE_MODE_FAILURE, tvName });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not delete Modes ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: ModesStateTypes.DELETE_MODE_FAILURE, tvName });
  });
};

export const syncModes = (roomName: string, tvName: string, modes: Mode[]): ThunkAction<void, IFullStorageShape, null, ModesStateActions> => (dispatch: any) => {
  dispatch({ type: ModesStateTypes.SYNC_MODES_BEGIN, tvName });
  return syncModesApi(roomName, tvName, modes)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: ModesStateTypes.SYNC_MODES_SUCCESS, tvName, modes: apiData.modes, roomName });
    } else {
      dispatch({ type: ModesStateTypes.SYNC_MODES_FAILURE, tvName });
    }
  })
  .catch((err) => {
    const errorMsg = get(err, 'response.data.message') || `Could not set modes ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: ModesStateTypes.SYNC_MODES_FAILURE, tvName });
  });
};
