import * as React from 'react';
import { AXSMenu, AXSTab, AXSBlock } from '@axs/axsui-components';
import { NavLink, withRouter } from "react-router-dom";
import styled from 'styled-components';


export const CustomNavLink = styled(NavLink)<any>`
  text-decoration: none;
`;


const MainNav = ({ location }: any) => (
    <AXSBlock>
        <AXSMenu horizontal={ true }>
            <AXSTab active={ location.pathname.includes('home') }><CustomNavLink to='/'>Server Status</CustomNavLink></AXSTab>
            <AXSTab active={ location.pathname.includes('stream') }><CustomNavLink to='/stream'>Stream</CustomNavLink></AXSTab>
            <AXSTab active={ location.pathname.includes('power') }><CustomNavLink to='/power'>Power</CustomNavLink></AXSTab>
            <AXSTab active={ location.pathname.includes('schedules') }><CustomNavLink to='/schedules'>Schedules</CustomNavLink></AXSTab>
            <AXSTab active={ location.pathname.includes('preset-pages') }><CustomNavLink to='/preset-pages'>Pages</CustomNavLink></AXSTab>
            <AXSTab active={ location.pathname.includes('modes') }><CustomNavLink to='/modes'>Modes</CustomNavLink></AXSTab>
            <AXSTab active={ location.pathname.includes('messages') }><CustomNavLink to='/messages'>Messages</CustomNavLink></AXSTab>
        </AXSMenu>
    </AXSBlock>);

//export default MainNav;
export default withRouter(MainNav);
