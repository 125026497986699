import { AnyAction } from 'redux';
import { Page } from '../../../common/models/page';

export interface IApplicationState {
    isAuthenticated: boolean;
    isCheckingHealth: object;
    isSendingPages: object;
    screenStatuses: object;
    serverStatuses: object;
    serverTimes: object;
    isGettingPages: object;
    isGettingScreenStatuses: object;
    isScreenPowering: object;
    currentPages: object;
    appMessage: string;
    selectedRoom: string | undefined;
    versions: object;
}

export enum SERVER_STATUS {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  STARTING = 'STARTING',
}

export const enum ApplicationStateTypes {
    LOGIN = 'LOGIN',
    LOGIN_FAIL = 'LOGIN_FAIL',
    LOGOUT = 'LOGOUT',
    SEND_PAGES_BEGIN = 'SEND_PAGES_BEGIN',
    SEND_PAGES_SUCCESS = 'SEND_PAGES_SUCCESS',
    SEND_PAGES_FAILURE = 'SEND_PAGES_FAILURE',
    GET_PAGES_BEGIN = 'GET_PAGES_BEGIN',
    GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS',
    GET_PAGES_FAILURE = 'GET_PAGES_FAILURE',
    HEALTH_CHECK_BEGIN = 'HEALTH_CHECK_BEGIN',
    HEALTH_CHECK_SUCCESS = 'HEALTH_CHECK_SUCCESS',
    HEALTH_CHECK_FAILURE = 'HEALTH_CHECK_FAILURE',
    TRIGGER_RESTART = 'TRIGGER_RESTART',
    SET_PAGE_TO_SEND = 'SET_PAGE_TO_SEND',
    SET_APP_MESSAGE = 'SET_APP_MESSAGE',
    GET_SCREEN_STATUS_BEGIN = 'GET_SCREEN_STATUS_BEGIN',
    GET_SCREEN_STATUS_SUCCESS = 'GET_SCREEN_STATUS_SUCCESS',
    GET_SCREEN_STATUS_FAILURE = 'GET_SCREEN_STATUS_FAILURE',
    POWER_SCREEN_BEGIN = 'POWER_SCREEN_BEGIN',
    POWER_SCREEN_END = 'POWER_SCREEN_END',
    SELECT_ROOM = 'SELECT_ROOM',
}

export interface ILogin extends AnyAction {
    type: ApplicationStateTypes.LOGIN;
}

export interface ILoginFail extends AnyAction {
    type: ApplicationStateTypes.LOGIN_FAIL;
}

export interface ILogout extends AnyAction {
    type: ApplicationStateTypes.LOGOUT;
}

export interface IPowerScreenBegin extends AnyAction {
    type: ApplicationStateTypes.POWER_SCREEN_BEGIN;
    tvName: string;
}

export interface IPowerScreenEnd extends AnyAction {
    type: ApplicationStateTypes.POWER_SCREEN_END;
    tvName: string;
}

// GET SCEEN STATUS
export interface IGetScreenStatusBegin extends AnyAction {
    type: ApplicationStateTypes.GET_SCREEN_STATUS_BEGIN;
    tvName: string;
}

export interface IGetScreenStatusSuccess extends AnyAction {
    type: ApplicationStateTypes.GET_SCREEN_STATUS_SUCCESS;
    tvName: string;
    status: boolean;
}

export interface IGetScreenStatusFailure extends AnyAction {
    type: ApplicationStateTypes.GET_SCREEN_STATUS_FAILURE;
    tvName: string;
    errorMsg: string;
}

// SEND PAGE
export interface ISendPagesBegin extends AnyAction {
    type: ApplicationStateTypes.SEND_PAGES_BEGIN;
    tvName: string;
}

export interface ISendPagesSuccess extends AnyAction {
    type: ApplicationStateTypes.SEND_PAGES_SUCCESS;
    tvName: string;
}

export interface ISendPagesFailure extends AnyAction {
    type: ApplicationStateTypes.SEND_PAGES_FAILURE;
    tvName: string;
    errorMsg: string;
}

//GET PAGE
export interface IGetPagesBegin extends AnyAction {
    type: ApplicationStateTypes.GET_PAGES_BEGIN;
    tvName: string;
}

export interface IGetPagesSuccess extends AnyAction {
    type: ApplicationStateTypes.GET_PAGES_SUCCESS;
    tvName: string;
    pages: Page[];
}

export interface IGetPagesFailure extends AnyAction {
    type: ApplicationStateTypes.GET_PAGES_FAILURE;
    tvName: string;
    errorMsg: string;
}

//HEALTH CHECK
export interface IHealthCheckBegin extends AnyAction {
    type: ApplicationStateTypes.HEALTH_CHECK_BEGIN;
    tvName: string;
}

export interface IHealthCheckSuccess extends AnyAction {
    type: ApplicationStateTypes.HEALTH_CHECK_SUCCESS;
    serverStatus: boolean;
    tvName: string;
    time: string;
    version: string;
}

export interface IHealthCheckFailure extends AnyAction {
    type: ApplicationStateTypes.HEALTH_CHECK_FAILURE;
    tvName: string;
}

export interface ITriggerRestart extends AnyAction {
    type: ApplicationStateTypes.TRIGGER_RESTART;
    tvName: string;
}

export interface ISetPageToSend extends AnyAction {
    type: ApplicationStateTypes.SET_PAGE_TO_SEND;
    tvName: string;
    index: number;
    page: Page;
}

export interface ISetAppMessage extends AnyAction {
    type: ApplicationStateTypes.SET_APP_MESSAGE;
    message: string
}

export interface ISelectRoom extends AnyAction {
    type: ApplicationStateTypes.SELECT_ROOM;
    roomName: string
}

export type ApplicationStateActions =
  ILogin |
  ILogout |
  ISendPagesBegin |
  ISendPagesSuccess |
  ISendPagesFailure |
  IHealthCheckBegin |
  IHealthCheckSuccess |
  IHealthCheckFailure |
  ITriggerRestart |
  ISetPageToSend |
  ISetAppMessage |
  IGetScreenStatusBegin |
  IGetScreenStatusSuccess |
  IGetScreenStatusFailure |
  IPowerScreenBegin |
  IPowerScreenEnd |
  ISelectRoom;
