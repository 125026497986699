import {
  IApplicationState,
  ApplicationStateActions,
  ApplicationStateTypes,
  IHealthCheckSuccess,
  IHealthCheckFailure,
  ISendPagesBegin,
  ISendPagesSuccess,
  ISendPagesFailure,
  IGetPagesBegin,
  IGetPagesSuccess,
  IGetPagesFailure,
  ISetPageToSend,
  ISetAppMessage,
  IGetScreenStatusBegin,
  IGetScreenStatusSuccess,
  IGetScreenStatusFailure,
  IPowerScreenBegin,
  IPowerScreenEnd,
  ISelectRoom,
  IHealthCheckBegin,
  SERVER_STATUS} from './types';
import config from '../../../config';

let sessionItem = localStorage.getItem('session');

//Verify the session has a valid value
const tvHosts = config.tvHosts;
let validSession = false;
if (tvHosts) {
  Object.keys(tvHosts).forEach((room: string) => {
    if (room === sessionItem) {
      validSession = true;
    }
  });
}

if (!validSession) {
  localStorage.removeItem('session');
  sessionItem = null;
}
export const initialState: IApplicationState = {
    isAuthenticated: sessionItem !== null,
    isCheckingHealth: {},
    isSendingPages: {},
    isGettingPages: {},
    isGettingScreenStatuses: {},
    isScreenPowering: {},
    screenStatuses: {},
    serverStatuses: {},
    serverTimes: {},
    currentPages: {},
    appMessage: '',
    selectedRoom: sessionItem || undefined,
    versions: {},
};

const ACTION_HANDLERS = {
  [ApplicationStateTypes.SELECT_ROOM]: (state: IApplicationState, { roomName }: ISelectRoom) => {
    const newState = { ...state };
    return Object.assign(newState, { selectedRoom: roomName, appMessage: '' });
  },
  [ApplicationStateTypes.LOGIN]: (state: IApplicationState) => {
    const newState = { ...state };
    return Object.assign(newState, { isAuthenticated: true, appMessage: '' });
  },
  [ApplicationStateTypes.LOGIN_FAIL]: (state: IApplicationState) => {
    const newState = { ...state };
    return Object.assign(newState, { isAuthenticated: false, appMessage: 'Wrong Magical word, Try again!' });
  },
  [ApplicationStateTypes.LOGOUT]: (state: IApplicationState) => {
    const newState = { ...state };
    return Object.assign(newState, { isAuthenticated: false, serverTimes: {}, currentPages: {},
      appMessage: '', selectedRoom: undefined, schedules: {}, versions: {} });
  },
  [ApplicationStateTypes.SEND_PAGES_BEGIN]: (state: IApplicationState, { tvName }: ISendPagesBegin) => {
    const newState = { ...state };
    const isSendingPages = { ...newState.isSendingPages };
    isSendingPages[tvName] = true;
    return Object.assign(newState, { isSendingPages });
  },
  [ApplicationStateTypes.SEND_PAGES_SUCCESS]: (state: IApplicationState, { tvName }: ISendPagesSuccess) => {
    const newState = { ...state };
    const isSendingPages = { ...newState.isSendingPages };
    isSendingPages[tvName] = false;
    return Object.assign(newState, { isSendingPages });
  },
  [ApplicationStateTypes.SEND_PAGES_FAILURE]: (state: IApplicationState, { tvName, errorMsg }: ISendPagesFailure) => {
    const newState = { ...state };
    const isSendingPages = { ...newState.isSendingPages };
    isSendingPages[tvName] = false;
    return Object.assign(newState, { isSendingPages, appMessage: errorMsg });
  },
  [ApplicationStateTypes.GET_PAGES_BEGIN]: (state: IApplicationState, { tvName }: IGetPagesBegin) => {
    const newState = { ...state };
    const isGettingPages = { ...newState.isGettingPages };
    isGettingPages[tvName] = true;
    return Object.assign(newState, { isGettingPages });
  },
  [ApplicationStateTypes.GET_PAGES_SUCCESS]: (state: IApplicationState, { tvName, pages }: IGetPagesSuccess) => {
    const newState = { ...state };
    const isGettingPages = { ...newState.isGettingPages };
    const currentPages = { ...newState.currentPages };
    currentPages[tvName] = pages;
    isGettingPages[tvName] = false;
    return Object.assign(newState, { isGettingPages, currentPages });
  },
  [ApplicationStateTypes.GET_PAGES_FAILURE]: (state: IApplicationState, { tvName }: IGetPagesFailure) => {
    const newState = { ...state };
    const isGettingPages = { ...newState.isGettingPages };
    isGettingPages[tvName] = false;
    return Object.assign(newState, { isGettingPages });
  },
  [ApplicationStateTypes.HEALTH_CHECK_BEGIN]: (state: IApplicationState, { tvName }: IHealthCheckBegin) => {
    const newState = { ...state };
    const isCheckingHealth = { ...newState.isCheckingHealth }
    isCheckingHealth[tvName] = true;
    return Object.assign(newState, { isCheckingHealth });
  },
  [ApplicationStateTypes.HEALTH_CHECK_SUCCESS]: (state: IApplicationState, { tvName, time, version, serverStatus }: IHealthCheckSuccess) => {
    const newState = { ...state };
    const serverStatuses = { ...newState.serverStatuses };
    const isCheckingHealth = { ...newState.isCheckingHealth }
    const serverTimes = { ...newState.serverTimes };
    const versions = { ...newState.versions };
    serverStatuses[tvName] = true;
    serverTimes[tvName] = time;
    versions[tvName] = version;
    isCheckingHealth[tvName] = false;
    serverStatuses[tvName] = serverStatus ? SERVER_STATUS.ONLINE : SERVER_STATUS.STARTING;;
    return Object.assign(newState, { serverStatuses, serverTimes, versions, isCheckingHealth });
  },
  [ApplicationStateTypes.HEALTH_CHECK_FAILURE]: (state: IApplicationState, { tvName }: IHealthCheckFailure) => {
    const newState = { ...state };
    const serverStatuses = { ... newState.serverStatuses };
    const isCheckingHealth = { ...newState.isCheckingHealth }
    serverStatuses[tvName] = false;
    isCheckingHealth[tvName] = false;
    return Object.assign(newState, { serverStatuses, isCheckingHealth });
  },
  [ApplicationStateTypes.SET_PAGE_TO_SEND]: (state: IApplicationState, { tvName, index, page }: ISetPageToSend) => {
    const newState = { ...state };
    const currentPages = { ...newState.currentPages };
    const newPage = page ? {url: page.url, delay: page.delay, autoscroll: page.autoscroll } : null;
    if (index === -1) {
      currentPages[tvName] = [newPage]
    } else if (page === null) {
      currentPages[tvName].splice(index, 1);
    } else if (currentPages[tvName] && !currentPages[tvName][index]) {
      currentPages[tvName].push(newPage);
    } else if (currentPages[tvName]) {
      currentPages[tvName][index] = newPage;
    }

    return Object.assign(newState, { currentPages });
  },
  [ApplicationStateTypes.SET_APP_MESSAGE]: (state: IApplicationState, { message }: ISetAppMessage) => {
    const newState = { ...state };
    return Object.assign(newState, { appMessage: message });
  },
  [ApplicationStateTypes.GET_SCREEN_STATUS_BEGIN]: (state: IApplicationState, { tvName }: IGetScreenStatusBegin) => {
    const newState = { ...state };
    const isGettingScreenStatuses = { ...newState.isGettingScreenStatuses };
    isGettingScreenStatuses[tvName] = true;
    return Object.assign(newState, { isGettingScreenStatuses });
  },
  [ApplicationStateTypes.GET_SCREEN_STATUS_SUCCESS]: (state: IApplicationState, { tvName, status }: IGetScreenStatusSuccess) => {
    const newState = { ...state };
    const isGettingScreenStatuses = { ...newState.isGettingScreenStatuses };
    const screenStatuses = { ... newState.screenStatuses };
    isGettingScreenStatuses[tvName] = false;
    screenStatuses[tvName] = status;
    return Object.assign(newState, { isGettingScreenStatuses, screenStatuses });
  },
  [ApplicationStateTypes.GET_SCREEN_STATUS_FAILURE]: (state: IApplicationState, { tvName, errorMsg }: IGetScreenStatusFailure) => {
    const newState = { ...state };
    const isGettingScreenStatuses = { ...newState.isGettingScreenStatuses };
    isGettingScreenStatuses[tvName] = false;
    return Object.assign(newState, { isGettingScreenStatuses, appMessage: errorMsg });
  },
  [ApplicationStateTypes.POWER_SCREEN_BEGIN]: (state: IApplicationState, { tvName }: IPowerScreenBegin) => {
    const newState = { ...state };
    const isScreenPowering = { ...newState.isScreenPowering };
    isScreenPowering[tvName] = true;
    return Object.assign(newState, { isScreenPowering });
  },
  [ApplicationStateTypes.POWER_SCREEN_END]: (state: IApplicationState, { tvName }: IPowerScreenEnd) => {
    const newState = { ...state };
    const isScreenPowering = { ...newState.isScreenPowering };
    isScreenPowering[tvName] = false;
    return Object.assign(newState, { isScreenPowering });
  },
};

export default function reducer(
    state: IApplicationState = initialState,
    action: ApplicationStateActions
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
