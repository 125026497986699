import axios, { AxiosRequestConfig } from 'axios';
import { TV_HOSTS, commonHeaders } from './ApiManager';
import { PresetPage } from '../common/models/presetPage';

export const getPresetPages = (roomName: string, tvName: string) => {
  const path = '/presetpages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const createPresetPage = (roomName: string, tvName: string, page: PresetPage) => {
  const path = '/presetpages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: page,
    method: 'POST',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const updatePresetPage = (roomName: string, tvName: string, page: PresetPage) => {
  const path = '/presetpages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: page,
    method: 'PUT',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};

export const deletePresetPage = (roomName: string, tvName: string, ids: number[]) => {
  const path = '/presetpages';
  const requestOptions: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    data: {
      ids,
    },
    method: 'DELETE',
    url: TV_HOSTS[roomName][tvName] + path
  };
  return axios(requestOptions);
};
