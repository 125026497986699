import {
  ISchedulesState,
  SchedulesStateActions,
  SchedulesStateTypes,
  IGetSchedulesBegin,
  IGetSchedulesSuccess,
  IGetSchedulesFailure,
  ICreateScheduleBegin,
  ICleanSchedules,
  ISyncSchedulesBegin,
  ISyncSchedulesSuccess,
  ISyncSchedulesFailure} from './types';
import config from '../../../config';

export const initialState: ISchedulesState = {
    isGettingSchedules: {},
    isSyncingSchedules: {},
    isUpdatingSchedules: {},
    schedules: [],
};

const beginHandler = (state: ISchedulesState, { tvName }: ICreateScheduleBegin) => {
  const newState = { ...state };
  const isUpdatingSchedules = { ...newState.isUpdatingSchedules };
  isUpdatingSchedules[tvName] = true;
  return Object.assign(newState, { isUpdatingSchedules });
}

const successHandler = (state: ISchedulesState, { tvName, schedules, roomName }: any) => {
  const newState = { ...state };
  const isUpdatingSchedules = { ...newState.isUpdatingSchedules };
  isUpdatingSchedules[tvName] = false;

  // Only update the reducer data from the main server
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (mainTv === tvName) {
    return Object.assign(newState, { isUpdatingSchedules, schedules });
  }
  return Object.assign(newState, { isUpdatingSchedules });
}

const failureHandler = (state: ISchedulesState, { tvName }: any) => {
  const newState = { ...state };
  const isUpdatingSchedules = { ...newState.isUpdatingSchedules };
  isUpdatingSchedules[tvName] = false;
  return Object.assign(newState, { isUpdatingSchedules });
}

const ACTION_HANDLERS = {
  [SchedulesStateTypes.CREATE_SCHEDULE_BEGIN]: beginHandler,
  [SchedulesStateTypes.DELETE_SCHEDULE_BEGIN]: beginHandler,
  [SchedulesStateTypes.UPDATE_SCHEDULE_BEGIN]: beginHandler,

  [SchedulesStateTypes.CREATE_SCHEDULE_SUCCESS]: successHandler,
  [SchedulesStateTypes.DELETE_SCHEDULE_SUCCESS]: successHandler,
  [SchedulesStateTypes.UPDATE_SCHEDULE_SUCCESS]: successHandler,

  [SchedulesStateTypes.CREATE_SCHEDULE_FAILURE]: failureHandler,
  [SchedulesStateTypes.DELETE_SCHEDULE_FAILURE]: failureHandler,
  [SchedulesStateTypes.UPDATE_SCHEDULE_FAILURE]: failureHandler,

  [SchedulesStateTypes.GET_SCHEDULES_BEGIN]: (state: ISchedulesState, { tvName }: IGetSchedulesBegin) => {
    const newState = { ...state };
    const isGettingSchedules = { ...newState.isGettingSchedules };
    isGettingSchedules[tvName] = true;
    return Object.assign(newState, { isGettingSchedules });
  },
  [SchedulesStateTypes.GET_SCHEDULES_SUCCESS]: (state: ISchedulesState, { tvName, schedules, roomName }: IGetSchedulesSuccess) => {
    const newState = { ...state };
    const isGettingSchedules = { ...newState.isGettingSchedules };
    isGettingSchedules[tvName] = false;
    const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
    if (mainTv === tvName) {
      return Object.assign(newState, { isGettingSchedules, schedules });
    }
    return Object.assign(newState, { isGettingSchedules });

  },
  [SchedulesStateTypes.GET_SCHEDULES_FAILURE]: (state: ISchedulesState, { tvName }: IGetSchedulesFailure) => {
    const newState = { ...state };
    const isGettingSchedules = { ...newState.isGettingSchedules };
    isGettingSchedules[tvName] = false;
    return Object.assign(newState, { isGettingSchedules });
  },
  [SchedulesStateTypes.SYNC_SCHEDULES_BEGIN]: (state: ISchedulesState, { tvName }: ISyncSchedulesBegin) => {
    const newState = { ...state };
    const isSyncingSchedules = { ...newState.isSyncingSchedules };
    isSyncingSchedules[tvName] = true;
    return Object.assign(newState, { isSyncingSchedules });
  },
  [SchedulesStateTypes.SYNC_SCHEDULES_SUCCESS]: (state: ISchedulesState, { tvName, schedules, roomName }: ISyncSchedulesSuccess) => {
    const newState = { ...state };
    const isSyncingSchedules = { ...newState.isSyncingSchedules };
    isSyncingSchedules[tvName] = false;
    const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
    if (mainTv === tvName) {
      return Object.assign(newState, { isSyncingSchedules, schedules });
    }
    return Object.assign(newState, { isSyncingSchedules });

  },
  [SchedulesStateTypes.SYNC_SCHEDULES_FAILURE]: (state: ISchedulesState, { tvName }: ISyncSchedulesFailure) => {
    const newState = { ...state };
    const isSyncingSchedules = { ...newState.isSyncingSchedules };
    isSyncingSchedules[tvName] = false;
    return Object.assign(newState, { isSyncingSchedules });
  },
  [SchedulesStateTypes.CLEAN_SCHEDULES]: (state: ISchedulesState, { }: ICleanSchedules) => {
    const newState = { ...state };
    return Object.assign(newState, { isSyncingSchedules: false, isGettingSchedules: false, isUpdatingSchedules: false, schedules: [] });
  },
};

export default function reducer(
    state: ISchedulesState = initialState,
    action: SchedulesStateActions
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
