import {
  IPresetPagesState,
  PresetPagesStateActions,
  PresetPagesStateTypes,
  IGetPresetPagesBegin,
  IGetPresetPagesSuccess,
  IGetPresetPagesFailure,
  ICreatePresetPageBegin,
  ICleanPresetPages} from './types';

export const initialState: IPresetPagesState = {
    isGettingPresetPages: false,
    isUpdatingPresetPages: false,
    presetPages: [],
};

const beginHandler = (state: IPresetPagesState, { }: ICreatePresetPageBegin) => {
  const newState = { ...state };
  return Object.assign(newState, { isUpdatingPresetPages: true });
}

const successHandler = (state: IPresetPagesState, { presetPages }: any) => {
  const newState = { ...state };
  return Object.assign(newState, { isUpdatingPresetPages: false, presetPages });
}

const failureHandler = (state: IPresetPagesState, { }: any) => {
  const newState = { ...state };
  return Object.assign(newState, { isUpdatingPresetPages: false });
}

const ACTION_HANDLERS = {
  [PresetPagesStateTypes.CREATE_PRESETPAGE_BEGIN]: beginHandler,
  [PresetPagesStateTypes.DELETE_PRESETPAGE_BEGIN]: beginHandler,
  [PresetPagesStateTypes.UPDATE_PRESETPAGE_BEGIN]: beginHandler,

  [PresetPagesStateTypes.CREATE_PRESETPAGE_SUCCESS]: successHandler,
  [PresetPagesStateTypes.DELETE_PRESETPAGE_SUCCESS]: successHandler,
  [PresetPagesStateTypes.UPDATE_PRESETPAGE_SUCCESS]: successHandler,

  [PresetPagesStateTypes.CREATE_PRESETPAGE_FAILURE]: failureHandler,
  [PresetPagesStateTypes.DELETE_PRESETPAGE_FAILURE]: failureHandler,
  [PresetPagesStateTypes.UPDATE_PRESETPAGE_FAILURE]: failureHandler,

  [PresetPagesStateTypes.GET_PRESETPAGES_BEGIN]: (state: IPresetPagesState, { }: IGetPresetPagesBegin) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingPresetPages: true });
  },
  [PresetPagesStateTypes.GET_PRESETPAGES_SUCCESS]: (state: IPresetPagesState, { presetPages }: IGetPresetPagesSuccess) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingPresetPages: false, presetPages });
  },
  [PresetPagesStateTypes.GET_PRESETPAGES_FAILURE]: (state: IPresetPagesState, { }: IGetPresetPagesFailure) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingPresetPages: false });
  },
  [PresetPagesStateTypes.CLEAN_PRESETPAGES]: (state: IPresetPagesState, { }: ICleanPresetPages) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingPresetPages: false, isUpdatingPresetPages: false, presetPages: [] });
  },
};

export default function reducer(
    state: IPresetPagesState = initialState,
    action: PresetPagesStateActions
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
