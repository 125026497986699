import { AnyAction } from 'redux';
import { Message } from '../../../common/models/messages';

export interface IMessagesState {
    isGettingMessages: boolean;
    isSyncingMessages: object;
    isUpdatingMessages: object;
    messages: Message[];
}

export const enum MessagesStateTypes {
  CLEAN_MESSAGES = 'CLEAN_MESSAGES',
  CREATE_MESSAGE_BEGIN = 'CREATE_MESSAGE_BEGIN',
  CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS',
  CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE',
  DELETE_MESSAGE_BEGIN = 'DELETE_MESSAGE_BEGIN',
  DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE',
  GET_MESSAGES_BEGIN = 'GET_MESSAGES_BEGIN',
  GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE',
  SYNC_MESSAGES_BEGIN = 'SYNC_MESSAGES_BEGIN',
  SYNC_MESSAGES_SUCCESS = 'SYNC_MESSAGES_SUCCESS',
  SYNC_MESSAGES_FAILURE = 'SYNC_MESSAGES_FAILURE',
  UPDATE_MESSAGE_BEGIN = 'UPDATE_MESSAGE_BEGIN',
  UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS',
  UPDATE_MESSAGE_FAILURE = 'UPDATE_MESSAGE_FAILURE',
}

//Messages
export interface ICleanMessages extends AnyAction {
    type: MessagesStateTypes.CLEAN_MESSAGES;
}

export interface IGetMessagesBegin extends AnyAction {
    type: MessagesStateTypes.GET_MESSAGES_BEGIN;
}

export interface IGetMessagesSuccess extends AnyAction {
    type: MessagesStateTypes.GET_MESSAGES_SUCCESS;
    messages: Message[];
}

export interface IGetMessagesFailure extends AnyAction {
    type: MessagesStateTypes.GET_MESSAGES_FAILURE;
}

export interface ISyncMessagesBegin extends AnyAction {
    type: MessagesStateTypes.SYNC_MESSAGES_BEGIN;
}

export interface ISyncMessagesSuccess extends AnyAction {
    type: MessagesStateTypes.SYNC_MESSAGES_SUCCESS;
    messages: Message[];
    tvName: string;
    roomName: string;
}

export interface ISyncMessagesFailure extends AnyAction {
    type: MessagesStateTypes.SYNC_MESSAGES_FAILURE;
}

export interface ICreateMessageBegin extends AnyAction {
    type: MessagesStateTypes.CREATE_MESSAGE_BEGIN;
    tvName: string;
}

export interface ICreateMessageSuccess extends AnyAction {
    type: MessagesStateTypes.CREATE_MESSAGE_SUCCESS;
    messages: Message[];
    tvName: string;
}

export interface ICreateMessageFailure extends AnyAction {
    type: MessagesStateTypes.CREATE_MESSAGE_FAILURE;
    tvName: string;
}

export interface IDeleteMessageBegin extends AnyAction {
    type: MessagesStateTypes.DELETE_MESSAGE_BEGIN;
    tvName: string;
}

export interface IDeleteMessageSuccess extends AnyAction {
    type: MessagesStateTypes.DELETE_MESSAGE_SUCCESS;
    messages: Message[];
    tvName: string;
}

export interface IDeleteMessageFailure extends AnyAction {
    type: MessagesStateTypes.DELETE_MESSAGE_FAILURE;
    tvName: string;
}

export interface IUpdateMessageBegin extends AnyAction {
    type: MessagesStateTypes.UPDATE_MESSAGE_BEGIN;
    tvName: string;
}

export interface IUpdateMessageSuccess extends AnyAction {
    type: MessagesStateTypes.UPDATE_MESSAGE_SUCCESS;
    messages: Message[];
    tvName: string;
}

export interface IUpdateMessageFailure extends AnyAction {
    type: MessagesStateTypes.UPDATE_MESSAGE_FAILURE;
    tvName: string;
}

export type MessagesStateActions =
  ICleanMessages |
  IGetMessagesBegin |
  IGetMessagesSuccess |
  IGetMessagesFailure |
  ISyncMessagesBegin |
  ISyncMessagesSuccess |
  ISyncMessagesFailure |
  ICreateMessageBegin |
  ICreateMessageSuccess |
  ICreateMessageFailure |
  IDeleteMessageBegin |
  IDeleteMessageSuccess |
  IDeleteMessageFailure |
  IUpdateMessageBegin |
  IUpdateMessageSuccess |
  IUpdateMessageFailure;
