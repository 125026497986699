import * as React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';

// Main Container/jsx Displayed on the page
const ModesContainer = Loadable({
    loader: () => import('./containers/ModesContainer'),
    loading: () => null
});


export default function (store: any, props: any) {
    // call Register Reducer here
    const match = props.match;
    return (
        <Switch>
            <Route exact={true} path={match.url} render={() => <ModesContainer {...store} {...props} />} />
        </Switch>
    );
}
