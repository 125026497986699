import {
  IModesState,
  ModesStateActions,
  ModesStateTypes,
  IGetModesBegin,
  IGetModesSuccess,
  IGetModesFailure,
  ICreateModeBegin,
  ICleanModes,
  ISyncModesBegin,
  ISyncModesSuccess,
  ISyncModesFailure} from './types';
import config from '../../../config';

export const initialState: IModesState = {
    isGettingModes: false,
    isSyncingModes: {},
    isUpdatingModes: {},
    modes: [],
};

const beginHandler = (state: IModesState, { tvName }: ICreateModeBegin) => {
  const newState = { ...state };
  const newUpdate = { ... newState.isUpdatingModes };
  newUpdate[tvName] = true;
  return Object.assign(newState, { isUpdatingModes: newUpdate });
}

const successHandler = (state: IModesState, { modes, tvName }: any) => {
  const newState = { ...state };
  const newUpdate = { ... newState.isUpdatingModes };
  newUpdate[tvName] = false;
  return Object.assign(newState, { isUpdatingModes: newUpdate, modes });
}

const failureHandler = (state: IModesState, { tvName }: any) => {
  const newState = { ...state };
  const newUpdate = { ... newState.isUpdatingModes };
  newUpdate[tvName] = false;
  return Object.assign(newState, { isUpdatingModes: newUpdate });
}

const ACTION_HANDLERS = {
  [ModesStateTypes.CREATE_MODE_BEGIN]: beginHandler,
  [ModesStateTypes.DELETE_MODE_BEGIN]: beginHandler,
  [ModesStateTypes.UPDATE_MODE_BEGIN]: beginHandler,

  [ModesStateTypes.CREATE_MODE_SUCCESS]: successHandler,
  [ModesStateTypes.DELETE_MODE_SUCCESS]: successHandler,
  [ModesStateTypes.UPDATE_MODE_SUCCESS]: successHandler,

  [ModesStateTypes.CREATE_MODE_FAILURE]: failureHandler,
  [ModesStateTypes.DELETE_MODE_FAILURE]: failureHandler,
  [ModesStateTypes.UPDATE_MODE_FAILURE]: failureHandler,

  [ModesStateTypes.GET_MODES_BEGIN]: (state: IModesState, { }: IGetModesBegin) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingModes: true });
  },
  [ModesStateTypes.GET_MODES_SUCCESS]: (state: IModesState, { modes }: IGetModesSuccess) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingModes: false, modes });
  },
  [ModesStateTypes.GET_MODES_FAILURE]: (state: IModesState, { }: IGetModesFailure) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingModes: false });
  },
  [ModesStateTypes.SYNC_MODES_BEGIN]: (state: IModesState, { tvName }: ISyncModesBegin) => {
    const newState = { ...state };
    const isSyncingModes = { ...newState.isSyncingModes };
    isSyncingModes[tvName] = true;
    return Object.assign(newState, { isSyncingModes });
  },
  [ModesStateTypes.SYNC_MODES_SUCCESS]: (state: IModesState, { tvName, modes, roomName }: ISyncModesSuccess) => {
    const newState = { ...state };
    const isSyncingModes = { ...newState.isSyncingModes };
    isSyncingModes[tvName] = false;
    const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
    if (mainTv === tvName) {
      return Object.assign(newState, { isSyncingModes, modes });
    }
    return Object.assign(newState, { isSyncingModes });

  },
  [ModesStateTypes.SYNC_MODES_FAILURE]: (state: IModesState, { tvName }: ISyncModesFailure) => {
    const newState = { ...state };
    const isSyncingModes = { ...newState.isSyncingModes };
    isSyncingModes[tvName] = false;
    return Object.assign(newState, { isSyncingModes });
  },
  [ModesStateTypes.CLEAN_MODES]: (state: IModesState, { }: ICleanModes) => {
    const newState = { ...state };
    return Object.assign(newState, { isGettingModes: false, isUpdatingModes: false, modes: [] });
  },
};

export default function reducer(
    state: IModesState = initialState,
    action: ModesStateActions
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action as any) : state; // @todo fix any crutch here.
}
