import {
  MessagesStateActions,
  MessagesStateTypes,
} from '../common/modules/messages/types';
import { ThunkAction } from 'redux-thunk';
import { IFullStorageShape } from '../store/app.types';
import {
  getMessages as getMessagesApi,
  createMessage as createMessageApi,
  deleteMessage as deleteMessageApi,
  syncMessages as syncMessagesApi,
  updateMessage as updateMessageApi } from '../api/messages';
import { AxiosResponse } from 'axios';
import { Message } from '../common/models/messages';
import { setAppMessage } from './appActions';
import { get } from 'lodash';
import config from '../config';

export const clearMessages = (): ThunkAction<void, IFullStorageShape, null, MessagesStateActions> => (dispatch: any) => {
  dispatch({ type: MessagesStateTypes.CLEAN_MESSAGES });
}

export const createMessage = (roomName: string, message: Message, tvName?: string): ThunkAction<void, IFullStorageShape, null, MessagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: MessagesStateTypes.CREATE_MESSAGE_BEGIN, tvName });
  return createMessageApi(roomName, tvName || mainTv, message)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: MessagesStateTypes.CREATE_MESSAGE_SUCCESS, messages: apiData.messages, tvName });
    } else {
      dispatch({ type: MessagesStateTypes.CREATE_MESSAGE_FAILURE, tvName });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not set messages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: MessagesStateTypes.CREATE_MESSAGE_FAILURE, tvName });
  });
};

export const updateMessage = (roomName: string, message: Message, tvName?: string): ThunkAction<void, IFullStorageShape, null, MessagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: MessagesStateTypes.UPDATE_MESSAGE_BEGIN, tvName });
  return updateMessageApi(roomName, tvName || mainTv, message)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: MessagesStateTypes.UPDATE_MESSAGE_SUCCESS, messages: apiData.messages, tvName });
    } else {
      dispatch({ type: MessagesStateTypes.UPDATE_MESSAGE_FAILURE, tvName });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not update messages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: MessagesStateTypes.UPDATE_MESSAGE_FAILURE, tvName });
  });
};

export const getMessages = (roomName: string): ThunkAction<void, IFullStorageShape, null, MessagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: MessagesStateTypes.GET_MESSAGES_BEGIN });
  return getMessagesApi(roomName, mainTv)
  .then((response: AxiosResponse<any>) => {
    const data = response.data;
    if (data.success) {
      dispatch({ type: MessagesStateTypes.GET_MESSAGES_SUCCESS, messages: data.messages });
    } else {
      dispatch({ type: MessagesStateTypes.GET_MESSAGES_FAILURE });
    }
  })
  .catch((err) => {
    const errorMsg = get(err, 'response.data.message') || `Could not get messages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: MessagesStateTypes.GET_MESSAGES_FAILURE });
  });
};

export const deleteMessage = (roomName: string, id: number, tvName?: string): ThunkAction<void, IFullStorageShape, null, MessagesStateActions> => (dispatch: any) => {
  const mainTv = roomName ? Object.keys(config.tvHosts[roomName])[0] : null;
  if (!mainTv) {
    return dispatch(setAppMessage('No Room selected'));
  }
  dispatch({ type: MessagesStateTypes.DELETE_MESSAGE_BEGIN, tvName });
  return deleteMessageApi(roomName, tvName || mainTv, [id])
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: MessagesStateTypes.DELETE_MESSAGE_SUCCESS, messages: apiData.messages, tvName });
    } else {
      dispatch({ type: MessagesStateTypes.DELETE_MESSAGE_FAILURE, tvName });
    }
  })
  .catch((err: any) => {
    const errorMsg = get(err, 'response.data.message') || `Could not delete messages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: MessagesStateTypes.DELETE_MESSAGE_FAILURE, tvName });
  });
};

export const syncMessages = (roomName: string, tvName: string, messages: Message[]): ThunkAction<void, IFullStorageShape, null, MessagesStateActions> => (dispatch: any) => {
  dispatch({ type: MessagesStateTypes.SYNC_MESSAGES_BEGIN, tvName });
  return syncMessagesApi(roomName, tvName, messages)
  .then((response: AxiosResponse<any>) => {
    const apiData = response.data;
    if (apiData.success) {
      dispatch({ type: MessagesStateTypes.SYNC_MESSAGES_SUCCESS, tvName, messages: apiData.messages, roomName });
    } else {
      dispatch({ type: MessagesStateTypes.SYNC_MESSAGES_FAILURE, tvName });
    }
  })
  .catch((err) => {
    const errorMsg = get(err, 'response.data.message') || `Could not set messages ${err}`;
    dispatch(setAppMessage(errorMsg));
    dispatch({ type: MessagesStateTypes.SYNC_MESSAGES_FAILURE, tvName });
  });
};
