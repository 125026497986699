import { combineReducers, Store } from 'redux';
import { ReducerMap, StoreShape } from './app.types';
import appReducer from '../common/modules/app/appReducer';
import presetPagesReducer from '../common/modules/presetpages/reducer';
import messagesReducer from '../common/modules/messages/reducer';
import modesReducer from '../common/modules/modes/reducer';
import schedulesReducer from '../common/modules/schedules/reducer';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
let asyncReducers: ReducerMap = {};

export const makeRootReducer = (history: any) => {
    const reducer = combineReducers({
        app: appReducer,
        presetPages: presetPagesReducer,
        messages: messagesReducer,
        modes: modesReducer,
        router: connectRouter(history),
        schedules: schedulesReducer,
        ...asyncReducers
    });

    return reducer;
}

const history = createBrowserHistory();

export function registerReducer(store: Store<StoreShape>, newReducers: ReducerMap): Store<StoreShape> {
    asyncReducers = { ...asyncReducers, ...newReducers };
    store.replaceReducer(makeRootReducer(history) as any);
    return store;
}
